@import "reset.scss";
@import "toastify.scss";
@import 'ui__var.scss';
@import 'bulma/css/bulma.css';

.wrapper {
  width: 100%;
}

.title {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  padding-top: 20px;
}

.main {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 15px;
  margin-top: 30px;
  width: 100%;
  overflow: auto;
}

.nav {
  display: flex;
  padding-left: 20px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;

  .link {
    margin-left: 20px;
    font-size: 25px;
  }
}

.button-reset {
  height: 40px !important;
  min-width: auto !important;
}

.table-block {
  padding: 20px 15px;
  min-width: 1700px;
}

.logout {
  width: 25px;
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}

.table {
  border: none;
  border-radius: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 165%;
  width: 100%;
}

.table-header {
  border-bottom: 1px solid #b6b6b6;
  border-top: 1px solid #b6b6b6;
  min-height: 40px;
}

.tr {
  display: grid;
  grid-template-columns: 0.5fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 1fr 1fr 1fr 1fr 0.8fr 0.8fr;
  font-family: Arial, Helvetica, sans-serif;
}

.cursor {
  cursor: pointer;
}

.td-name {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 5px;
}

.wrapper-drop {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;

  &__name {
    margin-top: 20px;
  }

  label {
    width: 350px;
    box-sizing: border-box;
  }
}

.td-hidden-name {
  display: none;
}

.wrapper-btn {
  display: flex;
  justify-content: space-around;
  padding: 30px;
}

.btn .wrapper-drop {
  width: auto;
}

.btn {
  &__save {
    width: 160px;
    height: 48px;
    text-transform: uppercase;
  }
}

.input-wrapper {
  display: grid;
  grid-template-columns: 0.5fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 1fr 1fr 1fr 1fr 0.8fr 0.8fr;
  gap: 5px;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 20px;

  label {
    width: 50%;
  }
}

.input-item {
	height: 40px;
	padding: 0 15px;
	border-radius: 5px;
	border: 1px solid #b6b6b6;
	outline: none;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
}


.td-name {
  display: flex;
  gap: 20px;

  img {
    width: 15px;
  }
}

.is-link {
  &::placeholder {
    font-size: 12px;
    color: #939393;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.list-wrapper {
  padding: 15px 20px;
}

.download {
  display: flex;
  justify-content: space-around;
  &:first-child {
    margin: 25px 0;
    // font-family: 'Tilt Neon', cursive;
    font-family: Arial, Helvetica, sans-serif;
  }

  &__text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
  }

  &__img {
    width: 20px;
    margin-left: 100px;
    cursor: pointer;
  }
}

.louder-wrapper-custom {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.blur {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(4px);
  background-color: #939393;
  opacity: 0.5;
}

.wrapper-oval {
  position: relative;
  z-index: 2;
}

.pagination {
  justify-content: center;

  li {
    margin-right: 10px;
  }
}

.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  margin-left: -1px;
}

.pagination>li.active>a {
  color: #fff;
  background-color: #7fb8ff;
  border-color: #7fb8ff;
}

.td {
  position: relative;
  display: flex;
  align-items: center;

  .icon {
    position: absolute;
    left: -20px;
    width: 20px;
    cursor: pointer;
  }
}

/* Style the active class (and buttons on mouse-over) */
// .pagination > li > a:hover {
//   background-color:  #218838;
//   color: white;
// }

.authorization-section {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 30px 0;
  &__container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
  }
}

.authorization {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  &__title {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
  }
  &__field {
      &+.authorization__field {
          margin-top: 20px;
      }
      &--numb{
          display: flex;
          justify-content: center;
          margin-bottom: 40px;
      }
  }
  &__details {
      margin-top: 16px;
      font-size: 14px;
      text-align: center;
      a, button {
          color: var(--ui-main-color);
          font-weight: 700;
      }
  }
  &__form {
      margin-top: 34px;
  }
  &__options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 10px;
  }
  &__options-full {
      width: 100%;
  }
  &__forgot-link {
      color: var(--ui-color-grey);
      font-size: 14px;
  }
  &__photo{
      margin: 25px 0;
      img{
          max-width: 100%;
      }
  }
}

.form-submit {
  margin-top: 34px;
}

.authorization-section {
  display: block;
  padding: 150px 0;

  @media screen and (max-width: 991px) {
    padding: 100px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 40px 0;
  }
}

.authorization-box {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
}

.authorization-form,
.form {
  border-radius: 20px;
  background: #fff;
  border: 1px solid var(--border-main);

  &__body {
    margin-left: auto;
    margin-right: auto;
  }
}

.form {
  &__body {
    margin-top: 24px;
  }

  &__header {
    &--fixed {
      max-width: 440px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.form-title {
  font-weight: 600;
  font-size: 34px;
  line-height: 1.5;
  font-family: Arial, Helvetica, sans-serif;

  &--center {
    text-align: center;
  }

  @media screen and (max-width: 1199px) {
    font-size: 30px;
  }

  @media screen and (max-width: 991px) {
    font-size: 28px;
  }

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}

.form-subtitle {
  margin-top: 12px;
  color: var(--text-color2);
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.78;

  p {
    font-family: Arial, Helvetica, sans-serif;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.form-text {
  margin-top: 12px;
  font-weight: 500;
  line-height: 1.65;
  font-size: 14px;
  color: var(--text-color2);

  &--center {
    text-align: center;
  }

  &--fw-600 {
    font-weight: 600;
  }

  &--mt-0 {
    margin-top: 0;
  }
}

.form-group {
  margin-top: 16px;
  &--more-mt {
    margin-top: 24px;
  }
}

.form-footer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  gap: 26px;

  &--more-mt {
    margin-top: 40px;
  }

  &--end {
    justify-content: flex-end;
  }
}

.input-name {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 600;
}

.input-notification {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  color: var(--text-color2);

  &--error {
    color: #d1513a;
  }

  &__icon {
    display: flex;
    width: 23px;
  }

  &--center {
    justify-content: center;
  }
}

.save-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  .link {
    margin-left: auto;
  }
}

.link {
  display: flex;
  color: var(--main-color);
  font-weight: 500;
  font-size: 14px;
  transition: 0.2s;
  font-family: Arial, Helvetica, sans-serif;

  &--bigger {
    font-size: 16px;
  }

  &--big {
    font-size: 18px;
  }

  &--type2 {
    margin: 36px 0;
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    text-decoration-line: underline;
    color: #622582;
    text-align: center;
  }
  &--decoration {
    text-decoration: underline;
  }
  &--type3 {
    font-weight: 500;
    color: #622582;
  }
  &--mt16 {
    margin-top: 16px;
  }

  &:hover {
    color: darken(#e41472, 10%);
  }
}

.back-to {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 18px;
  color: var(--main-color);

  &__arrow {
    display: flex;
    width: 32px;
  }
}

.property-dividends-grid .input .input-wrapper .input-item {
  width: 100%;
  color: #9e9e9e;
  text-align: start;
}

.input-wrapper .react-datepicker-wrapper {
  width: 100%;
}

.property-dividends-grid .input .input-wrapper .input-item-custom {
  color: #000;

  &::placeholder {
    color: #9e9e9e;
  }
}

.input-item-custom-button {
  display: flex;
  align-items: center;
}

.input-item-light-custom {
  color: #9e9e9e;
}

.input {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  
  &__name {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
  }
  &__notification {
      margin: 8px 0 0 0;
      font-size: 14px;
      color: var(--ui-error-color);
  }
  &--error {
      .input-item, .textarea{
          border: 2px solid var(--ui-error-color);
      }
  }
  &--success {
      .input-item {
          border: 2px solid var(--ui-success-color);
      }
  }
  &--numb{
      width: 40px;
      margin: 0 8px;
      .input-item{
          padding: 0;
          border: none;
          border-bottom: 2px solid var(--ui-main-color);
          border-radius: 0;
          font-size: 24px;
          text-align: center;
          &.active{
              border-color: var(--ui-input-border-color);
          }
      }
  }
}

.input-wrapper {
  position: relative;
}

.input-item {
  display: block;
  width: 100%;
  height: 44px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid var(--ui-input-border-color);
  outline: none;    
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  transition: all 0.15s ease;
  // @include input-placeholder {
  //     color: #b6b6b6;
  //     transition: all 0.15s ease;
  //     font-size: 14px;
  // }
  &--left-icon {
      padding-left: 45px;
  }
  &--right-double-icon {
      padding-right: 60px;
  }
  &--right-icon-and-action {
      padding-right: 90px;
  }
  &--right-icon {
      padding-right: 45px;
  }
  &--bigger {
      height: 52px;
      font-size: 16px;
  }
}

.input-icon {
  position: absolute;
  top: 50%;transform: translateY(-50%);
  display: flex;
  left: 15px;
  width: 16px;
  svg {
      width: 100%;
      height: auto;
  }
  .stroke path {
      stroke: #b6b6b6;
  }
  &--right {
      left: unset;
      right: 15px;
  }
  &--more-right {
      left: unset;
      right: 45px;
  }
}

.input-action {
  position: absolute;
  top: 50%;transform: translateY(-50%);
  display: flex;
  left: 15px;
  font-weight: 700;
  color: var(--ui-main-color);
  &--more-right  {
      left: unset;
      right: 45px
  }
  &--right {
      left: unset;
      right: 15px
  }
  
}

.password-type {
  display: none;
}

input[type="password"] ~.show-pass {
  .password-type {
      display: block;
  }
  .text-type {
      display: none;
  }
}

.show-pass {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;    
  display: flex;
  width: 16px;
  svg {
      width: 100%;
      height: auto;
  }
  .fill path {
      fill: #b6b6b6;
  }    
  .stroke path {
      stroke: #b6b6b6;
  }
}


.textarea{
  padding: 10px 15px;
  border-radius: 5px;
  font: inherit;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--ui-input-border-color);
  textarea {
      
      display: block;
      width: 100%;
      min-height: 120px;
      background: none;
      border: none;
      outline: none !important;
      font-size: 14px;    
      // @include input-placeholder {
      //     color: #b6b6b6;
      //     transition: all 0.15s ease;
      // }
      resize: vertical;
      scrollbar-color:  #b6b6b6 transparent ;
      scrollbar-width: thin;
      
      &::-webkit-scrollbar {
        width: 6px; 
        height: 6px;          
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #b6b6b6;
        cursor: pointer;
      }
  }
}

.select-block {
  &__name {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
  }
}

.select {
  position: relative;
  display: flex;
  align-content: center;
  height: 44px;    
  border-radius: 5px;
  // border: 1px solid var(--ui-input-border-color);
  background: #fff;
  z-index: 3;
  &__current {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 15px;
      font-size: 14px;
  }
  &__current-arrow {
      display: flex;
      flex: 0 0 8px;
      max-width: 8px;
      margin-left: 10px;
      .stroke path {
          stroke:  #424242;
      }
  }
  &__current-text {
      margin-right: auto;
  }
  &__img {       
      display: flex;
      width: 16px;
      align-items: center;
      margin-right: 12px;
  }
  &__drop {
      display: none;
      position: absolute;        
      left: 0;
      top: calc(100% + 10px);        
      width: 100%;
      padding: 5px;
      background: #FFFFFF;
      border: 1px solid var(--ui-select-border);
      border-radius: 4px;
      z-index: 1;
      width: max-content;
      white-space: nowrap;
      font-family: Arial, Helvetica, sans-serif;

      ul {
          >li {
              >a, >button {
                  display: block;
                  width: 100%;
                  padding: 10px;
                  border-radius: 4px;
                  color: #000;
                  font-size: 14px;
                  transition: all 0.15s ease;
                  text-align: left;
                  &:hover {
                      background: #f4f4f4;
                  }
              }
          }
      }
  }
  &__drop-scroll {
      max-height: 180px;
      overflow: auto;
      scrollbar-color:  #b6b6b6 transparent ;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 4px; 
        height: 4px;          
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #b6b6b6;
      }
  }
  &.active {
      z-index: 5;
      .select__drop {
          display: block;
      }
  }
  &--small {
      padding: 0;
      border: 0;
      width: 62px;
      z-index: auto;
      .select__drop {
          padding: 0;
          min-width: 100px;
      }
  }
  &--grey {
      background: #DADADA;
      padding: 0 15px;
      width: 74px;
  }
  &--light {
      .select__current {
          color: #b6b6b6;
          font-weight: 300;
          font-size: 16px;
          line-height: 18px;
      }
  }
  &--big {
      height: 52px;
      font-size: 16px;
  }
}

/*--------------End_Inputs-----------*/




/*--------------Buttons-------------*/


button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.button {
  display: inline-flex;
  align-items: center;justify-content: center;
  width: auto;
  min-width: 110px;
  height: 44px;
  gap: 8px;
  padding: 0 15px;
  background: var(--ui-button-bg);
  border: none;    
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;    
  transition: all 0.15s ease;
  &:hover {
      background: var(--ui-button-bg-hover);
  }
  &--second-type {
      background: transparent;
      border: 1px solid var(--ui-button-bg);
      color: #000;
      &:hover {
          background: var(--ui-button-bg);
          color: #fff;
      }
  }  
  &--rounded {
      border-radius: 22px;
  }
  &--less-height {
      height: 36px;
  }
  &--bigger-height {
      height: 60px;
      font-size: 18px
  }
  &--full-width {
      width: 100%;
  }
  &--small {
      width: 120px;
  }
  &--regular {
      width: 160px;
  }
  &--wide {
      width: 180px;
  }
  &--wider {
      width: 200px;
  }
  &--big {
      width: 240px;
  }
 
  &:disabled {
      opacity: 0.5;
      background: #b6b6b6;
      cursor: default;
  }

  &__arrow {
      display: flex;
      align-items: center;        
      width: 6px;        
  }
  &__icon {
      display: flex;
      width: 12px;        
      .fill path{
          fill: #fff;
      }
  }
}


/*--------------End_Buttons---------*/




/*-----Checkbox and Radiobutton-----*/


.checkbox {
  margin: 0 0 10px 0;
  &__item {
      display: flex;
      align-items: center;justify-content: center;        
      flex: 0 0 16px;
      width: 16px;
      height: 16px;
      border: 1px solid var(--ui-checkbox-border);
      border-radius: 3px;
      cursor: pointer;
  }
  &__item-icon {
      display: flex;
      width: 10px;
      opacity: 0;
      .fill path {
          fill: #fff;
      }
  }
  &__label {
      display: flex;
      align-items: center;
      
  }
  &__text {
      margin-left: 10px;
      font-size: 14px;
      cursor: pointer;
      a {
          color: blue;
          text-decoration: underline !important;
      }
  }
  input:checked~.checkbox__item {
      background: var(--ui-checkbox-bg);
      border-color: var(--ui-checkbox-bg);
      .checkbox__item-icon {
          opacity: 1;
      }
  }
  input:disabled ~.checkbox__item{
      cursor: default;
      background: #b6b6b6;
      &+.checkbox__text {
          cursor: default;
      }
  }
  &--no-mb {
      margin-bottom: 0;
  }

}

.radio {
  margin: 0 0 10px 0;
  &__item {
      position: relative;
      display: flex;
      align-items: center;justify-content: center;
      flex: 0 0 16px;
      width: 16px;
      height: 16px;
      border: 1px solid var(--ui-checkbox-border);
      border-radius: 50%;
      cursor: pointer;
      &:after {
          content: '';
          position: absolute;
          top: 50%;left: 50%;
          transform: translate(-50%, -50%);
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #fff;
          z-index: 2;
          opacity: 0;
      }
  }

  &__label {
      display: flex;
      align-items: center;
      
  }
  &__text {
      margin-left: 10px;
      font-size: 14px;
      cursor: pointer;
      a {
          color: blue;
      }
  }
  input:checked~.radio__item {
      background: var(--ui-checkbox-bg);     
      border-color: var(--ui-checkbox-bg);   
      &:after {
          opacity: 1;
      }
  }
  input:disabled ~.radio__item{
      cursor: default;
      background: #b6b6b6;
      &+.radio__text {
          cursor: default;
      }
  }

}

.switch {
  display: flex;
  align-items: center;
  &__toggler {
      position: relative;
      display: block;
      width: 50px;        
      height: 26px;        
      border: 1px solid var(--ui-swith-color);
      border-radius: 13px;
      cursor: pointer;
      &:before {
          content: "";
          position: absolute;
          left: 2px;
          top: 50%;transform: translateY(-50%);
          width: 18px;
          height: 18px;
          background: var(--ui-swith-color);
          border-radius: 100%;
          transition: .3s;
      }                
  }
  input:checked~.switch__toggler {
      //background: #e8e8e8;
      &:before {
          left: 26px;
          background: var(--ui-main-color);
      }
  }
  &__label {
      &+.switch__text {
          margin-left: 10px;
      } 
  }
  &__text {
      font-size: 14px;
      font-weight: 500;
      &+.switch__label {
          margin-left: 10px;
      }
  }
  &--type2 {
      .switch__toggler {
          background: var(--ui-swith-color);
          &:before {
              background: #fff;
          }
      }
      input:checked~.switch__toggler {
          background: var(--ui-main-color);
          &:before {
              background: #fff;
          }
      }
  }
}


/*---End_Checkbox and Radiobutton---*/



/*-------------Popup----------------*/


.popup-open {
  position: relative;
  overflow: hidden;
}

.popup-window {
  position: fixed;
  display: block ;    
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 201;
  background: rgba(0,0,0,0.5);
  &__inside {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 100%;
      padding: 20px ;
  }
}

.popup {
  position: relative;
  width: 500px;
  max-width: 100%;
  border-radius: 10px;
  background: var(--ui-popup-bg);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 16px;
      svg {
          width: 100%;
          height: auto;
      }
      .fill path {
          fill: #000;
      }
  }
}

.popup-header {
  &__title {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
  }
}

.popup-body {
  margin-top: 20px;
}

.popup-text {
  margin: 0 0 15px 0;
  line-height: 2;
  font-size: 15px;
  &--center {
      text-align: center;
  }
}

.popup-submit {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  &--less-mt {
      margin-top: 15px;
  }
  &--more-mt {
      margin-top: 45px;
  }
  &__col {
      width: calc(50% - 10px);
  }
  &--sb {
      justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .popup {
      padding: 30px 15px 20px 15px;
  }

  .popup-header__title {
      font-size: 21px;
  }
}
/*-------------End_Popup------------*/


/*-------------Form, Authorization----------------*/


.authorization-section {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 30px 0;
  &__container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
  }
}

.authorization {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  &__title {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
  }
  &__field {
      &+.authorization__field {
          margin-top: 20px;
      }
      &--numb{
          display: flex;
          justify-content: center;
          margin-bottom: 40px;
      }
  }
  &__details {
      margin-top: 16px;
      font-size: 14px;
      text-align: center;
      a, button {
          color: var(--ui-main-color);
          font-weight: 700;
      }
  }
  &__form {
      margin-top: 34px;
  }
  &__options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 10px;
  }
  &__options-full {
      width: 100%;
  }
  &__forgot-link {
      color: var(--ui-color-grey);
      font-size: 14px;
  }
  &__photo{
      margin: 25px 0;
      img{
          max-width: 100%;
      }
  }
}

.form-submit {
  margin-top: 34px;
}
/*-------------END_Form, Authorization----------------*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.button {
  gap: 8px;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  background: var(--main-color);

  &:hover {
    background: darken(#e41472, 10%);
  }

  &--authorization {
    font-weight: 500;
  }

  &--type2 {
    background: transparent;
    border: 1px solid var(--border-color-main);
    color: var(--text-color-main);

    &:hover {
      background: transparent;
      border-color: var(--main-color);
    }
  }

  &--type3 {
    background: var(--accent-third-color);

    &:hover {
      background: var(--accent-secondary-color);
    }
  }

  &--type4 {
    background: #fff;
    border: 2px solid var(--main-color);
    color: var(--main-color);

    .fill path {
      fill: var(--main-color);
    }

    &:hover {
      background: var(--main-color);
      color: #fff;

      .fill path {
        fill: #fff;
      }
    }
  }

  &--type5 {
    background: #fff;
    border: 1px solid var(--main-color);
    color: var(--text-color-main);

    .fill path {
      fill: var(--main-color);
    }

    &:hover {
      background: #fff;
      color: var(--main-color);

      .fill path {
        fill: #fff;
      }
    }
  }

  &--type6 {
    background: var(--button-bg2);
    color: var(--text-color-main);

    &:hover {
      background: var(--button-bg2);
      color: var(--main-color);
    }
  }

  &--type7 {
    background: #f3f3f3;
    color: #25252e;

    &:hover {
      background: #dddddd;
      color: #25252e;
    }
  }

  &:disabled {
    background: #b6b6b6;
    opacity: 1;
    color: #fff;

    &:hover {
      background: #b6b6b6;
    }
  }

  &--width-auto {
    width: auto;
    min-width: auto;
  }
  &--w125 {
    width: 125px;
  }

  &--small {
    width: 150px;
  }

  &--long {
    width: 275px;
  }

  &--medium {
    width: 200px;
  }

  &--mob-full-width {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }

  &--smaller-height {
    height: 38px;
  }

  &--width-180 {
    width: 180px;
  }

  &--big-height {
    height: 60px;
  }

  &--action-type {
    width: 45px;
    height: 45px;
    min-width: unset;
    padding: 0 8px;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--fs-14 {
    font-size: 14px;
  }
}

.popup-link {
  display: block;
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #e41472;
  text-align: center;
}

.btn-icon {
  display: flex;
  width: 21px;

  .fill path,
  .stroke path {
    transition: 0.2s;
  }

  &--width-24 {
    width: 24px;
  }
}

.input-icon {
  width: 24px;

  &--calendar {
    width: 24px;
  }

  .stroke-type path {
    stroke: var(--text-color2);
  }

  &--color {
    .fill path {
      fill: var(--main-color);
    }
  }
}

.input {
  margin-bottom: 16px;

  &--no-mb {
    margin-bottom: 0;
  }

  &--error {
    .input-item {
      border-color: #d1513a;
    }
  }
  &--mt40 {
    margin-top: 40px;
  }
  &--mb24 {
    margin-bottom: 24px;
  }
  &--small {
    width: 160px;
  }
  &--big-width {
    max-width: 500px;
    width: 100%;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  &--search {
    max-width: 380px; 
    width:  100%;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  &--limitations {
    width: 100%;
    max-width: 265px;
  }
}

.input-notification--error {
  margin-top: -15px;
  font-family: Arial, Helvetica, sans-serif;
}


.td-value {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.checkbox {
	margin: 0 0 10px 0;
	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 16px;
		width: 16px;
		height: 16px;
		border: 1px solid var(--ui-checkbox-border);
		border-radius: 3px;
		cursor: pointer;
	}
	&__item-icon {
		display: flex;
		width: 10px;
		opacity: 0;
		.fill path {
			fill: #fff;
		}
	}
	&__label {
		display: flex;
		align-items: center;
	}
	&__text {
		margin-left: 10px;
		font-size: 14px;
		cursor: pointer;
		a {
			color: blue;
			text-decoration: underline !important;
		}
	}
	input:checked ~ .checkbox__item {
		background: var(--ui-checkbox-bg);
		border-color: var(--ui-checkbox-bg);
		.checkbox__item-icon {
			opacity: 1;
		}
	}
	input:disabled ~ .checkbox__item {
		cursor: default;
		background: #b6b6b6;
		& + .checkbox__text {
			cursor: default;
		}
	}
	&--no-mb {
		margin-bottom: 0;
	}
}

.sum {
  text-align: end;
}


