:root {
	/*--------Main--------*/

	--ui-main-color: #d81159;

	--ui-button-bg: #d81159;
	--ui-button-bg-hover: #ee0259;

	--ui-color-grey: #a6b0c3;

	--ui-block-bg: #fff;
	--ui-border-color: #c9c9c9;
	--ui-border-light-color: #eeeeee;

	--ui-input-border-color: #b6b6b6;

	--ui-checkbox-border: #b6b6b6;
	--ui-checkbox-bg: #d81159;

	--ui-swith-color: #e1e0e0;

	/*---------Text-------*/

	--ui-text-color: #000;
	--ui-text-color-light: #8f9bba;
	--ui-text-color-grey: #9ea2a9;
	--ui-error-color: #eb5757;
	--ui-success-color: #27ae60;

	/*---------End_Text-----*/

	--ui-select-border: #b6b6b6;

	--ui-popup-bg: #fff;

	--ui-pagination-bg: #fff;
	--ui-pagination-border: #b6b6b6;
	--ui-pagination-color: #000;

	--ui-upload-box-color: #53c48a;

	--ui-progress-bg: #d4f0e2;
	--ui-progress-line: #88d39f;
	--ui-progress-label-bg: #1f2937;

	--ui--tooltip-bg: #1c1c1e;

	
}
